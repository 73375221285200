import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { Form, FieldArray } from 'vee-validate';
import InlineSvg from 'vue-inline-svg';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import './css/application.css';
import TheFooter from './components/the-footer.vue';
import TheHeader from './components/the-header.vue';
import HomeView from './components/home-view.vue';
import OrderView from './components/order-view.vue';
import RepeatOrderView from './components/repeat-order-view.vue';
import BaseSelect from './components/base-select.vue';
import BaseButton from './components/base-button.vue';
import BaseAlert from './components/base-alert.vue';
import BaseInput from './components/base-input.vue';
import BaseNoticeBar from './components/base-notice-bar.vue';
import BaseModal from './components/base-modal.vue';
import BaseStepper from './components/base-stepper.vue';
import OrderTracking from './components/order-tracking.vue';
import './locales/yup';
import { Locales } from './locales/locales';
const i18n = createI18n({ legacy: false, locale: 'es', fallbackLocale: 'es', messages: Locales.messages }); // eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => { const app = createApp({ components: { TheFooter, TheHeader, HomeView, OrderView, RepeatOrderView, OrderTracking } }); const pinia = createPinia(); app.component('VForm', Form); app.component('VFieldArray', FieldArray); app.component('BaseSelect', BaseSelect); app.component('BaseButton', BaseButton); app.component('BaseInput', BaseInput); app.component('InlineSvg', InlineSvg); app.component('BaseAlert', BaseAlert); app.component('BaseNoticeBar', BaseNoticeBar); app.component('BaseModal', BaseModal); app.component('BaseStepper', BaseStepper); app.use(FloatingVue); app.use(pinia); app.use(i18n); app.use(VueQueryPlugin); pinia.use(piniaPluginPersistedstate); app.mount('#vue-app'); return app; });
